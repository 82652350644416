export function hexToRgba(hex: any, alpha: any) {
    // remove symbol # (if exist)
    hex = hex.replace(/^#/, '');

    // converting
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // check alpha
    const a = alpha !== undefined ? parseFloat(alpha) : 1;

    // return in rgba format
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}
